<template>
    <div class="table">
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item><i class="el-icon-lx-cascades"></i> 兑换记录管理</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div class="handle-box">
                <!--<el-button type="danger" icon="delete" class="handle-del mr10" @click="delAll">批量删除</el-button>-->
                <!--<el-select v-model="select_cate" placeholder="筛选省份" class="handle-select mr10">-->
                    <!--<el-option key="1" label="广东省" value="广东省"></el-option>-->
                    <!--<el-option key="2" label="湖南省" value="湖南省"></el-option>-->
                <!--</el-select>-->
                <el-input v-model="select_word" placeholder="请输入兑换码、姓名、电话" class="handle-input mr10"></el-input>
                <el-button type="primary" icon="search" @click="search" class="mr10">搜索</el-button>
                <el-select v-model="selectedStatus" class="mr10" @change="change">
                    <el-option
                            v-for="item in statusList"
                            :key="item.id"
                            :label="item.title"
                            :value="item.id">{{item.title}}
                    </el-option>
                </el-select>
                <!--<el-button type="primary" style="float: right" @click="exportExcel">导出为Excel</el-button>-->
                <export-excel :apiUrl="apiUrl" :params="params" style="float: right" :filename="filename" :tHeader="tHeader" :tValue="tValue"></export-excel>

            </div>
            <el-table :data="data" border class="table" ref="multipleTable" @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="55" align="center"></el-table-column>
                <el-table-column prop="id" label="ID" width="70" align="center"></el-table-column>
                <el-table-column prop="username" align="center" label="姓名" width="120">
                </el-table-column>
                <el-table-column prop="tel" align="center" label="手机号码" width="120">
                </el-table-column>
                <el-table-column prop="code" align="center" label="兑换码" width="120">
                    <template slot-scope="scope">
                        <div style="color:#FF0000; font-weight: bold;">{{scope.row.code}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="title" align="center" label="奖品名称">
                </el-table-column>
                <el-table-column prop="imgid"  align="center" label="奖品图片" width="140">
                    <template   slot-scope="scope">
                        <el-popover
                                placement="left"
                                title=""
                                width="500"
                                trigger="hover">
                            <img :src="scope.row.imgUrl" style="max-width: 100%" />
                            <img slot="reference" :src="scope.row.imgUrl" :alt="scope.row.imgUrl" style="max-width: 130px; height: auto; max-height: 100px">
                        </el-popover>
                        <!--<img :src="scope.row.b_image"  min-width="70" height="70" />-->
                    </template>
                </el-table-column>
                <el-table-column prop="integral" align="center" label="积分" width="100">
                </el-table-column>
                <el-table-column prop="status" align="center" label="领取状态" width="120">
                    <template slot-scope="scope">
                        <div v-if="scope.row.status==0" style="color:#EDA200">未领取</div>
                        <div v-else-if="scope.row.status==1" style="color:#008000">已领取</div>
                    </template>
                </el-table-column>
                <el-table-column prop="adddatetime"  align="center" label="兑换时间" sortable width="155">
                </el-table-column>
                <el-table-column prop="receivedatetime"  align="center" label="领取时间" sortable width="155">
                </el-table-column>
                <el-table-column label="操作" width="150" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" v-if="scope.row.status==0" icon="el-icon-edit" @click="handleEdit(scope.$index)">设置已领取</el-button>
                        <!--<el-button type="text" icon="el-icon-delete" class="red" @click="handleDelete(scope.$index, scope.row)">删除</el-button>-->
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination background @current-change="handleCurrentChange" layout="prev, pager, next" :total="sumPage">
                </el-pagination>
            </div>
        </div>

        <!-- 编辑弹出框 -->
        <el-dialog title="编辑" v-loading="loading" :visible.sync="editVisible" width="30%">
            <el-form ref="form" :model="form" label-width="100px">
                <el-form-item label="领奖状态">
                    <el-select v-model="form.statusMsg" placeholder="请选择领奖状态">
                        <el-option
                                v-for="item in statusList"
                                :key="item.id"
                                :label="item.title"
                                :value="item.id">{{item.title}}
                        </el-option>
                        <!--<el-option key="bbk" label="步步高" value="bbk"></el-option>-->
                        <!--<el-option key="xtc" label="小天才" value="xtc"></el-option>-->
                        <!--<el-option key="imoo" label="imoo" value="imoo"></el-option>-->
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="hideEditVisible">取 消</el-button>
                <el-button type="primary" @click="saveEdit('form')">确 定</el-button>
            </span>
        </el-dialog>

        <!-- 删除提示框 -->
        <el-dialog title="提示" :visible.sync="delVisible" width="300px" center>
            <div class="del-dialog-cnt">删除不可恢复，是否确定删除？</div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="delVisible = false">取 消</el-button>
                <el-button type="primary" @click="deleteRow">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import  ExportExcel from '.././utils/index';
    export default {
        name: 'basetable',
        components: { ExportExcel},
        data() {
            return {
                url: './vuetable.json',
                tableData: [],
                cur_page: 1,  //当前页
                number: 10,  //每页显示条数
                sumPage:10,  //总页数
                multipleSelection: [],
                select_cate: '',
                select_word: '',
                del_list: [],
                is_search: false,
                editVisible: false,
                delVisible: false,
                form: {
                    id:'',
                    userid: '',
                    username: '',
                    tel:'',
                    code:'',
                    title:'',
                    imgid:'',
                    imgUrl: '',
                    integral: '',
                    status: '',
                    adddatetime:'',
                    receivedatetime:'',
                },
                idx: -1,
                dialogVisible: false,
                AddOrSave:'',  //1表示添加，2表示更新
                loading:false,
                statusList:[  //状态列表
                    {id:9,title:"全部"},
                    {id:0,title:"未领取"},
                    {id:1,title:"已领取"},
                ],
                selectedStatus: 9,
                excelData:[],  //表格数据
                tHeader:['ID', '姓名', '手机号码', '兑换码', '奖品名称', '积分', '领取状态', '兑换时间', '领取时间'],
                tValue:['id', 'username', 'tel', 'code', 'title', 'integral', 'status', 'adddatetime','receivedatetime'],
                filename:'15周年庆活动奖品兑换记录',
                apiUrl: "FissionPrizeRecord/exportExcel",
                params: null,
            }
        },
        created() {
            //获取初始数据
            this.getData();
        },
        computed: {
            data() {
                return this.tableData.filter((d) => {
                    let is_del = false;
                    for (let i = 0; i < this.del_list.length; i++) {
                        if (d.title === this.del_list[i].title) {
                            is_del = true;
                            break;
                        }
                    }
                    return d;
                    // if (!is_del) {
                    //     if (d.b_datetime.indexOf(this.select_cate) > -1 &&
                    //         (d.b_titile.indexOf(this.select_word) > -1 ||
                    //             d.b_datetime.indexOf(this.select_word) > -1)
                    //     ) {
                    //         return d;
                    //     }
                    // }
                })
            }
        },
        methods: {
            // 分页导航
            handleCurrentChange(val) {
                this.cur_page = val;
                this.getData();
            },
            // 获取 easy-mock 的模拟数据
            getData() {
                // 开发环境使用 easy-mock 数据，正式环境使用 json 文件
                // if (process.env.NODE_ENV === 'development') {
                //     this.url = '/ms/table/list';
                // };
                //不传参数要写null
                this.params={
                    select_word: this.select_word,
                    status: this.selectedStatus,
                };
                var params=this.$qs.stringify({
                    select_word: this.select_word,
                    status: this.selectedStatus,
                    pageIndex: this.cur_page,
                    number: this.number
                });
                // console.log(params);
                this.$api.post('FissionPrizeRecord/getWinPrizeList', params, res => {
                    this.tableData = res.data.list;
                    this.sumPage = res.data.sumPage*10;
                    this.cur_page = res.data.currentPage;
                    console.log(this.tableData);
                }, err => {
                    this.tableData = [];
                    this.$message.error(err.msg);
                });
                // this.$axios.post(this.url, {
                //     page: this.cur_page
                // }).then((res) => {
                //     this.tableData = res.data.list;
                //     console.log(this.tableData);
                // })
            },
            search() {
                this.is_search = true;
                this.getData();
            },
            formatter(row, column) {
                return row.nickname;
            },
            filterTag(value, row) {
                return row.tag === value;
            },
            handleEdit(index) {
                const item = this.tableData[index];
                this.$confirm('确认要设置为已领取状态嘛?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    var params=this.$qs.stringify({
                        id: item.id
                    });
                    this.$api.post('FissionPrizeRecord/completeOrderRecord', params, res => {
                        this.$message.success(res.msg);
                        this.getData();
                    }, err => {
                        this.$message.error(err);
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '操作已取消'
                    });
                });
            },
            handleDelete(index, row) {
                this.idx = index;
                this.form = row;
                this.delVisible = true;
            },
            delAll() {
                const length = this.multipleSelection.length;
                let str = '';
                this.del_list = this.del_list.concat(this.multipleSelection);
                for (let i = 0; i < length; i++) {
                    str += this.multipleSelection[i].b_title + ' ';
                }
                console.log(this.del_list);
                // this.$message.error('删除了' + str);
                // this.multipleSelection = [];
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            change(){
                // console.log(this.selectedStatus);
                this.getData();
            },
            // 保存编辑
            saveEdit(formName) {
                this.editVisible = false;
                var params=null;
                //1表示添加，2表示更新
                if(this.AddOrSave==1){
                    params=this.$qs.stringify({
                        title: this.form.title,
                        number: this.form.number
                    });
                }else{
                    params=this.$qs.stringify({
                        id: this.form.id,
                        status: this.form.statusMsg,
                    });
                }
                // console.log(params);
                // return;
                this.$api.post('WinPrize/saveWinPrize', params, res => {
                    this.getData();
                    this.$message.success(res.msg);
                }, err => {
                    this.$message.error(err.msg);
                });




                // this.$message.success(`修改第 ${this.idx+1} 行成功`);
            },
            // 确定删除
            deleteRow(){
                // console.log(this.form);
                // return;
                var params=this.$qs.stringify({
                    id: this.form.id
                });
                this.$api.post('InCode/deleteInCode', params, res => {
                    this.getData();
                    this.$message.success(res.msg+res.data+"条数据");
                }, err => {
                    this.$message.error(err.msg);
                });
                this.delVisible = false;
            },


            hideEditVisible(){
                this.editVisible=false;
            },

        }
    }

</script>

<style scoped>
    .handle-box {
        margin-bottom: 20px;
    }

    .handle-select {
        width: 120px;
    }
    .mr10{
        margin-right: 10px;
    }
    .handle-input {
        width: 300px;
        display: inline-block;
    }
    .del-dialog-cnt{
        font-size: 16px;
        text-align: center
    }
    .table{
        width: 100%;
        font-size: 14px;
    }
    .red{
        color: #ff0000;
    }
    .mr10{
        margin-right: 10px;
    }
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 100%;
        /*height: 100%;*/
        display: block;
    }
</style>
